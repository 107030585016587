<template>
  <div>
    <Loader v-if="loading" />
    <Page
      v-if="appointment && validated"
      data-test-id="appointmentPage"
      :loading="loading"
      :title="`Appointment #${job.id} Summary`"
    >
      <CustomerInvoiceBalance
        v-if="
          invoice && invoice.financials.balance_cents > 0 && isBillingCustomer
        "
        data-test-id="customerInvoiceBalance"
        :invoice="invoice"
        class="mb-6 full-width"
      >
        <template #actions>
          <div class="d-flex w-full">
            <div class="flex-1 d-flex justify-center">
              <button class="link" @click="showPaymentModal = true">
                Make a Payment
              </button>
            </div>
            <div class="flex-1 d-flex justify-center">
              <button class="link" @click="handleDownload">
                Download Invoice
              </button>
            </div>
          </div>
        </template>
      </CustomerInvoiceBalance>

      <v-btn
        v-if="
          invoice && invoice.financials.balance_cents === 0 && isBillingCustomer
        "
        data-test-id="downloadInvoiceButton"
        color="primary"
        small
        text
        rounded
        @click="handleDownload"
      >
        <v-icon class="subtitle-1 mr-1">mdi-download</v-icon>
        Download Invoice
      </v-btn>

      <p
        v-if="isCancelled"
        data-test-id="cancelledMessage"
        class="subtitle-2 warning--text mb-4 text-center"
      >
        Cancelled by {{ cancelledBy }} -
        {{ appointment.cancellation_reason.reason }}
      </p>

      <span class="overline text-left full-width">Summary</span>
      <v-card
        data-test-id="summaryCard"
        class="full-width bordered-card mb-4 pa-3"
      >
        <div
          class="d-flex justify-space-between subtitle-1 grey--text text--darken-2"
        >
          <p>{{ appointment.service_category.name }}</p>
          <p>{{ invoice ? dollars(invoice.financials.total_cents) : "" }}</p>
        </div>
        <div class="d-flex flex-column grey--text text--darken-2 caption">
          <p>{{ addressShortDisplay }}</p>
          <p>Booked on {{ completedDate }}</p>
          <p v-if="appointment.departure_time">
            Completed on {{ completedTime }}
          </p>
        </div>
      </v-card>

      <div v-if="invoice && isBillingCustomer" class="full-width">
        <v-alert v-if="$vuetify.breakpoint.xsOnly" type="warning" dense>
          Invoice best viewed in Landscape mode. Rotate your device for the best
          experience.
        </v-alert>
        <span class="overline text-left full-width">Invoice</span>
        <v-card
          class="full-width bordered-card mb-4 pa-3"
          style="font-size: 0.75rem !important; overflow-y: scroll"
        >
          <Invoice
            data-test-id="invoice"
            :invoice="invoice"
            :customizations="customizations"
            :tenant-timezone="tenantTimezone"
            tech-mode
          />
        </v-card>
      </div>

      <div
        v-if="
          (visiblePhotos.length || visibleDocuments.length) && isBillingCustomer
        "
        data-test-id="photosAndDocuments"
        class="full-width"
      >
        <span class="overline text-left full-width">Files</span>
        <v-card class="full-width bordered-card mb-4 pa-3">
          <div v-if="visibleDocuments">
            <v-subheader>Documents</v-subheader>
            <div class="d-flex flex-wrap">
              <div
                v-for="document in visibleDocuments"
                :key="document.id"
                class="secondary--text d-flex flex-column justify-center align-center ma-2 doc full-width"
                :class="{ desktopdoc: !$vuetify.breakpoint.smAndDown }"
                @click="handleViewDocument(document.url)"
              >
                <v-icon size="75" color="secondary"
                  >mdi-file-pdf-outline</v-icon
                >
                <span class="caption document-name">{{ document.name }}</span>
              </div>
            </div>
          </div>
          <div v-if="visiblePhotos.length">
            <v-subheader>Photos</v-subheader>
            <PhotoGallery :job-id="job.id" :photos="visiblePhotos" readonly />
          </div>
        </v-card>
      </div>
    </Page>
    <InvoicePaymentModal
      v-model="showPaymentModal"
      data-test-id="invoicePaymentModal"
      :customer="user"
      :invoice="invoice"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { addressShortLabel } from "@/lib/address";

import InvoicePaymentModal from "@/components/InvoicePaymentModal.vue";
import Loader from "@/components/Loader.vue";
import Page from "@/components/Page.vue";
import PhotoGallery from "@/components/PhotoGallery.vue";
import CustomerInvoiceBalance from "@/modules/paymentMethods/components/CustomerInvoiceBalance.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";

export default {
  components: {
    Loader,
    CustomerInvoiceBalance,
    InvoicePaymentModal,
    Page,
    PhotoGallery
  },
  setup() {
    const { definePaymentCallbacks } = usePaymentMethods();
    return { definePaymentCallbacks };
  },
  data() {
    return {
      loading: true,
      showPaymentModal: false,
      details: [],
      dialog: false,
      notAllowedStatuses: [
        "pending",
        "assigned",
        "accepted",
        "running",
        "late",
        "preferred_assignment"
      ],
      cancelledStatuses: ["cancelled", "canceled"],
      validated: false
    };
  },
  computed: {
    ...mapState({
      user: "user",
      job: "job",
      appointment({ job }) {
        if (!job) {
          return null;
        }
        const { appointments } = job;
        return appointments.find((appt) => appt.id == this.$route.params.id);
      },
      address: ({ job }) => {
        return job && job.address ? job.address : null;
      },
      invoice: ({ job }) => {
        if (!job) return null;
        return job.invoice;
      },
      isBillingCustomer({ user }) {
        if (!this.invoice) return false;
        return (
          this.invoice.billing_customer?.customer_id.toString() ===
          user?.id.toString()
        );
      },
      customizations: ({ customizations, tenant }) => {
        return { ...customizations, Tenant: tenant };
      },
      tenantTimezone({ tenant }) {
        if (!tenant) return "";
        return tenant.timezone;
      }
    }),
    addressShortDisplay() {
      return addressShortLabel(this.address);
    },
    completedTime() {
      return new Date(this.appointment.departure_time).toLocaleDateString();
    },
    completedDate() {
      return new Date(this.appointment.scheduled_time).toLocaleDateString(
        "en-us"
      );
    },
    cancelledBy() {
      if (
        this.appointment.cancellation_reason &&
        this.appointment.cancellation_reason.responsable !== "Customer"
      ) {
        return this.$store.state.tenant.name;
      }
      return "Customer";
    },
    isCancelled() {
      //remove first part of this check once API is fixed in MAP4B
      return (
        this.appointment.cancellation_reason &&
        this.cancelledStatuses.includes(this.appointment.status)
      );
    },
    visiblePhotos() {
      return this.job.photos.filter((photo) => photo.customer_visible);
    },
    visibleDocuments() {
      return this.job.documents.filter((doc) => doc.customer_visible);
    }
  },
  watch: {
    appointment(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setDetails();
      }
    },
    "$route.params.id": function () {
      this.loadAppointment();
    }
  },
  async mounted() {
    this.loadAppointment();
    this.definePaymentCallbacks({
      onPaymentSuccess: this.loadAppointment
    });
  },
  methods: {
    ...mapActions(["fetchAppointment"]),
    handleDownload() {
      window.open(this.invoice.pdf_url, "downloadInvoice");
    },
    handleViewDocument(url) {
      window.open(url, "previewDocument");
    },
    async loadAppointment() {
      this.loading = true;
      try {
        const res = await this.fetchAppointment(this.$route.params.id);
        this.validated = true;
        // If appointment is still current, redirect to active view
        if (this.notAllowedStatuses.includes(res.data.status)) {
          this.$router.replace({
            name: "appointment",
            params: { id: res.data.id }
          });
        }
      } catch (e) {
        // do nothing
      }
      this.loading = false;
    },
    setDetails() {
      let techName = "None assigned";
      if (this.appointment && this.appointment.technician) {
        techName = `${this.appointment.technician.first_name} ${this.appointment.technician.last_name}`;
      }
      this.details = [
        {
          label: "Technician",
          value: techName
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin: 0 !important;
}
.payment-method {
  text-transform: capitalize;
}
.doc {
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  flex: 1;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 5px;
  &:hover {
    color: var(--v-primary-base) !important;
    .v-icon {
      color: var(--v-primary-base) !important;
    }
  }
}
.document-name {
  text-align: center;
  word-break: break-word;
}

.desktopdoc {
  max-width: 300px;
}

.full-width {
  width: 100%;
}
</style>
