<template>
  <div v-if="user">
    <Page title="Schedule Appointment" no-banner no-divider>
      <v-form
        ref="form"
        v-model="formIsValid"
        lazy-validation
        @submit.prevent="handleSubmit"
      >
        <div v-if="user.children?.length">
          <span class="overline ml-1">Who needs service?</span>
          <v-card class="mb-4 mt-1 pa-3 bordered-card">
            <v-select
              id="sub-account-selector"
              v-model="selectedAccountId"
              outlined
              label="Account"
              :items="allAccounts"
              :rules="requiredRules"
            ></v-select>
          </v-card>
        </div>
        <span class="overline ml-1">Contact Details</span>
        <v-card class="mb-4 mt-1 pa-3 bordered-card">
          <div>
            <b
              >{{ selectedAccount.first_name }}
              {{ selectedAccount.last_name }}</b
            >
          </div>
          <v-divider class="divider"></v-divider>
          <div
            class="d-flex justify-space-between flex-wrap caption align-center"
          >
            <span>{{ email }}</span>
            <span v-if="phoneNumber">{{ phoneNumber }}</span>
            <span
              v-else
              class="red--text d-flex align-center"
              @click="toggleModal('missingPhoneModal')"
            >
              <v-icon color="red" small class="mr-1">mdi-alert-circle</v-icon
              >Missing Phone
            </span>
          </div>
        </v-card>
        <span class="overline ml-1">Where do you need service?</span>
        <v-card class="mb-4 mt-1 pa-3 bordered-card">
          <div v-if="addresses.length">
            <v-select
              id="default-address-selector"
              v-model="addressId"
              outlined
              label="Address"
              :items="addresses"
              :rules="addressRules(currentAddress)"
              :error-messages="addressValidationErrorMessage"
            >
              <template slot="selection" slot-scope="addresses">
                <div v-if="!isAddressValid(addresses.item)">
                  <div class="selected-option">
                    <v-icon class="error-icon" color="error"
                      >mdi-alert-circle</v-icon
                    >
                    <span class="red--text">{{ addresses.item.text }}</span>
                  </div>
                </div>
                <div v-else>{{ addresses.item.text }}</div>
              </template>
              <template slot="item" slot-scope="addresses">
                <div v-if="!isAddressValid(addresses.item)">
                  <v-icon class="error-icon" color="error"
                    >mdi-alert-circle</v-icon
                  >
                  <span class="red--text">{{ addresses.item.text }}</span>
                </div>
                <div v-else>{{ addresses.item.text }}</div>
              </template>
            </v-select>
            <div class="d-flex justify-end">
              <v-btn
                color="secondary"
                rounded
                outlined
                small
                @click="handleAddAddress"
              >
                <v-icon left>mdi-plus</v-icon>Add Address
              </v-btn>
            </div>
          </div>
          <ProvideAddress v-else schedule @provide-address="handleAddAddress" />
        </v-card>

        <span class="overline ml-1">What can we help you with?</span>
        <v-card class="mb-4 mt-1 pa-3 pb-0 bordered-card">
          <v-select
            v-model="serviceCategoryId"
            outlined
            label="I'm having problems with..."
            :items="serviceCategoryOptions"
            :rules="requiredRules"
          ></v-select>
        </v-card>

        <v-scroll-y-transition mode="out-in">
          <div
            v-if="
              fullServiceCategory &&
              addressId &&
              !currentAddress.invalid &&
              !addressValidationErrorMessage
            "
          >
            <span class="overline ml-1"
              >Select the appointment date & time.</span
            >
            <div v-if="appointmentEmailMessage">
              {{ appointmentEmailMessage }}
            </div>
            <TimeSlots
              :service-category="fullServiceCategory"
              :address="address"
              :handle-select-slot="handleSelectSlot"
              :handle-no-slots-available="handleNoAppointmentsAvailable"
              :reset-selection="resetLocalData"
              :error-message="slotError"
            />
          </div>

          <v-timeline v-else class="mb-4 timeline">
            <v-timeline-item color="secondary" small>
              <template #opposite>
                <h2 class="headline font-weight-light secondary--text">
                  Next Step
                </h2>
              </template>
              <div class="py-4 caption">
                Select a date & time from our available appointments...
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-scroll-y-transition>

        <v-scroll-y-transition>
          <div v-if="serviceTime">
            <span class="overline ml-1">Tell us more... (optional)</span>
            <AdditionalDetailsForm
              v-model="photos"
              :service-category="fullServiceCategory"
              :update-details="updateDetails"
              :update-promo="(val) => (promoCode = val)"
            />
          </div>
        </v-scroll-y-transition>

        <div v-if="appointmentsAvailable" class="text-center">
          <v-btn type="submit" color="primary" rounded :loading="loading"
            >Book Appointment</v-btn
          >
        </div>
      </v-form>
      <SmallModal
        v-if="missingPhoneModal"
        btn-text="Provide Phone Number"
        @click="handlePhoneAlertClick"
        @hide-modal="toggleModal('missingPhoneModal')"
      >
        <div slot="body">
          Please provide us with your phone number to schedule an appointment.
        </div>
      </SmallModal>
      <SmallModal
        v-if="missingAddressModal"
        btn-text="Provide Address"
        @click="handleAddressAlertClick"
        @hide-modal="toggleModal('missingAddressModal')"
      >
        <div slot="body">
          Please provide us with your current address to schedule an
          appointment.
        </div>
      </SmallModal>
      <SmallModal
        v-if="invalidAddressModal"
        btn-text="Correct Address"
        @click="handleAddressAlertClick"
        @hide-modal="toggleModal('invalidAddressModal')"
      >
        <div slot="body">
          <p>
            <b>We Can't Find this Address</b>
          </p>
          <p>{{ currentAddress.street }}, {{ currentAddress.zip_code }}</p>
          <p>Please correct the address to schedule an appointment.</p>
        </div>
      </SmallModal>
      <AccountInfoForm
        v-if="profileModal"
        @hide-modal="toggleModal('profileModal')"
        @save-success="showPhoneSuccessToast"
      />
      <AddressForm
        v-if="addressModal"
        ref="addressForm"
        :key="addressKey.toString()"
        :current-address="addingAddress ? null : currentAddress"
        @hide-modal="handleReset"
        @save-success="handleAddressSaveSuccess"
      />
      <SuccessToast v-if="correctPhoneSuccess">
        <div slot="body">
          <div>Thank you for adding your phone number.</div>
          <div>You can now schedule an appointment.</div>
        </div>
      </SuccessToast>
      <SuccessToast v-if="correctAddressSuccess">
        <div slot="body">
          <div>Thank you for correcting your address.</div>
        </div>
      </SuccessToast>
      <SuccessToast v-if="addAddressSuccess">
        <div slot="body">
          <div>Thank you for adding your address.</div>
        </div>
      </SuccessToast>
    </Page>
  </div>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { mapActions, mapState } from "vuex";
import { addressLabel } from "@/lib/address";

import AdditionalDetailsForm from "@/components/AdditionalDetailsForm.vue";
import AddressForm from "@/components/AddressForm.vue";
import Page from "@/components/Page.vue";
import TimeSlots from "@/components/TimeSlots.vue";
import AccountInfoForm from "../components/AccountModal/AccountInfoForm.vue";
import SmallModal from "../components/SmallModal.vue";
import SuccessToast from "../components/SuccessToast.vue";
import AuthMixin from "../mixins/auth";
import ValidationRules from "../mixins/validationRules";
import ValidationMixin from "../mixins/validationRules";
import ProvideAddress from "./ProvideAddress.vue";

const INITIAL_STATE = {
  addingAddress: false,
  addressId: "",
  address: {},
  addressKey: new Date(),
  zipCode: "",
  serviceCategoryId: "",
  fullServiceCategory: null,
  serviceTime: "",
  arrivalWindowStart: "",
  arrivalWindowEnd: "",
  promoCode: "",
  photos: [],
  details: {},
  addressModal: false,
  appointmentsAvailable: true,
  loading: false,
  slotError: "",
  profileModal: false,
  missingPhoneModal: false,
  missingAddressModal: false,
  invalidAddressModal: false,
  correctPhoneSuccess: false,
  correctAddressSuccess: false,
  addAddressSuccess: false
};

export default {
  name: "ScheduleAppointment",
  components: {
    AdditionalDetailsForm,
    TimeSlots,
    Page,
    AddressForm,
    AccountInfoForm,
    SmallModal,
    SuccessToast,
    ProvideAddress
  },
  mixins: [AuthMixin, ValidationMixin, ValidationRules],
  data() {
    return { ...INITIAL_STATE, selectedAccountId: null };
  },
  computed: {
    ...mapState({
      user: "user",
      serviceCategories: "serviceCategories",
      appointmentEmailMessage({ tenant }) {
        if (!tenant || !tenant.customizations) {
          return "";
        }
        const { customizations } = tenant;
        return (customizations.General.appointment_email_message || "").trim();
      }
    }),
    allAccounts() {
      return [this.user].concat(this.user.children).map((acc) => ({
        text: `${acc.first_name} ${acc.last_name}`,
        value: acc.id,
        ...acc
      }));
    },
    selectedAccount() {
      if (!this.selectedAccountId) return this.user;
      return this.allAccounts.find((acc) => acc.id === this.selectedAccountId);
    },
    addresses() {
      return this.selectedAccount.addresses.map((a) => {
        return {
          text: addressLabel(a),
          value: a.id,
          ...a
        };
      });
    },
    email() {
      const primary = this.selectedAccount.emails.find(
        (email) => email.primary
      );
      if (!primary) {
        return "Missing Email";
      }
      const totalEmails = this.selectedAccount.emails.length;
      return `${primary.email_address}${
        totalEmails > 1 ? ` (${totalEmails})` : ""
      }`;
    },
    phoneNumber() {
      const primary = this.selectedAccount.phones.find(
        (phone) => phone.primary
      );
      if (!primary) {
        return null;
      }
      const totalPhones = this.selectedAccount.phones.length;
      return `${primary.phone_number}${
        totalPhones > 1 ? ` (${totalPhones})` : ""
      }`;
    },
    serviceCategoryOptions() {
      return this.serviceCategories.map((c) => ({
        text: c.name,
        value: c.id
      }));
    },
    currentAddress() {
      return this.selectedAccount.addresses.find(
        (address) => address.id === this.addressId
      );
    }
  },
  watch: {
    selectedAccountId(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Reset form fields when account is changed
        this.resetForm();
      }
    },
    addressId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.address = this.selectedAccount.addresses.find(
          (a) => a.id.toString() === newValue.toString()
        );
        this.resetLocalData();
      }
    },
    serviceCategoryId(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.fullServiceCategory = this.serviceCategories.find(
          (category) => category.id.toString() === newValue.toString()
        );
        this.resetLocalData();
        this.validateContactInfo();
      }
    },
    user(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectPrimaryAddress();
        this.selectedAccountId = newValue.id;
      }
    }
  },
  mounted() {
    if (this.$store.state.user.parent) {
      this.$router.push("/");
    }
    API.serviceCategories.getAll().then((res) => {
      this.$store.commit("replaceState", {
        state: "serviceCategories",
        data: res.data
      });
    });
    this.selectPrimaryAddress();
    if (this.user) {
      this.selectedAccountId = this.user.id;
    }
  },
  methods: {
    ...mapActions([
      "createAppointment",
      "fetchRecentAppointments",
      "uploadPhotos"
    ]),
    handleLogout() {
      this.logout();
    },
    handleNoAppointmentsAvailable() {
      this.appointmentsAvailable = false;
    },
    handleReset() {
      this.addressKey = new Date();
      this.toggleModal("addressModal");
    },
    handleSubmit() {
      if (this.validateContactInfo() && this.$refs.form.validate()) {
        if (this.serviceTime) {
          this.slotError = "";
          this.loading = true;
          let apiId = null;
          this.createAppointment({
            service_category_id: this.serviceCategoryId,
            address_id: this.address.id,
            details: this.details,
            service_time: this.serviceTime,
            scheduled_time: this.serviceTime,
            arrival_window_start: this.arrivalWindowStart,
            arrival_window_end: this.arrivalWindowEnd,
            promoCode: this.promoCode,
            scheduled_in_web_app: true,
            customer_id: this.selectedAccount.id
          })
            .then((res) => {
              apiId = res.data.id;
              window.gtag("event", "schedule", {
                event_category: "appointment"
              });
              return this.uploadPhotos({
                appointmentId: res.data.id,
                photos: this.photos
              }).catch(() => {
                // Handle failed photo upload
                this.handleCreateSuccess(apiId);
              });
            })
            .then(() => {
              this.handleCreateSuccess(apiId);
            })
            .catch((e) => {
              console.log(e);
              this.loading = false;
            });
        } else {
          this.slotError = "Date & time selection required.";
        }
      }
    },
    handleCreateSuccess(id) {
      this.loading = false;
      // Refresh user appointments
      this.fetchRecentAppointments();
      this.$router.push({ name: "appointment", params: { id } });
    },
    handleSelectSlot(slot) {
      this.serviceTime = slot.scheduled_time;
      this.arrivalWindowStart = slot.arrival_window_start;
      this.arrivalWindowEnd = slot.arrival_window_end;
      this.slotError = "";
    },
    updateDetails(data) {
      this.details = { ...this.details, ...data };
    },
    resetLocalData() {
      // Reset state when changing address or service category
      this.serviceTime = "";
      this.arrivalWindowStart = "";
      this.arrivalWindowEnd = "";
      this.appointmentsAvailable = true;
    },
    selectPrimaryAddress() {
      this.addressId = this.$store.getters.primaryAddress.id;
    },
    toggleModal(modal) {
      this[modal] = !this[modal];
    },
    handlePhoneAlertClick() {
      this.toggleModal("missingPhoneModal");
      this.toggleModal("profileModal");
    },
    handleAddressAlertClick() {
      this.missingAddressModal = false;
      this.invalidAddressModal = false;
      this.toggleModal("addressModal");
    },
    showPhoneSuccessToast(correctedInfo) {
      if (correctedInfo) {
        this.correctPhoneSuccess = true;
      }
    },
    isAddressValid(listItem) {
      return this.isValidExisting(listItem);
    },
    handleAddressSaveSuccess(correctedValues) {
      this.correctAddressSuccess = correctedValues;
      this.addAddressSuccess = !correctedValues;
    },
    validateContactInfo() {
      // Check for missing phone, missing address or invalid address
      if (!this.phoneNumber) {
        this.toggleModal("missingPhoneModal");
        return false;
      }
      if (!this.addresses.length) {
        this.toggleModal("missingAddressModal");
        return false;
      }
      if (this.currentAddress.invalid) {
        this.toggleModal("invalidAddressModal");
        return false;
      }
      return true;
    },
    handleAddAddress() {
      this.addingAddress = true;
      this.toggleModal("addressModal");
    },
    resetForm() {
      Object.keys(INITIAL_STATE).forEach(
        (key) => (this[key] = INITIAL_STATE[key])
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.overline {
  text-align: left;
}
.v-card {
  min-width: 350px;
}
.v-form {
  max-width: 768px;
  width: 100%;
}
.timeline {
  height: 95px;
  padding-top: 0 !important;
}
.divider {
  margin: 2px 0;
}

.error-icon {
  font-size: 18px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.selected-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
