<template>
  <v-card class="full-width">
    <v-card-title class="d-flex justify-end">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        outlined
        clearable
        class="search"
        @click:clear="resetQuery"
        @keydown.enter="updateQuery"
      ></v-text-field>
    </v-card-title>
    <v-card-text
      v-if="instructionText"
      class="caption mr-4 mt-4 pb-1 text-left inline"
      >{{ instructionText }}
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="results"
      :loading="loading"
      :mobile-breakpoint="5"
      :page="reportParams.page"
      :items-per-page="reportParams.per_page"
      :must-sort="true"
      :sort-by="reportParams.sort_by"
      :sort-desc="reportParams.sort_desc"
      :server-items-length="totalResults"
      :loading-text="loadingText"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
      class="datatable"
      @click:row="$emit('click:row', $event)"
      @update:options="updateOptions"
    >
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import isEqual from "lodash/isEqual";
import API from "serviceshift-ui/api-client";

export default {
  name: "Datatable",

  props: {
    headers: { type: Array, required: true },
    reportPath: { type: String, required: true },
    page: { type: Number, default: 1 },
    perPage: { type: Number, default: 10 },
    sortBy: { type: String, default: null },
    sortDesc: { type: Boolean, default: null },
    query: { type: String, default: null },
    loadingText: { type: String, default: "Loading..." },
    instructionText: { type: String, default: null },
    customerId: { type: String, default: "" },
    rowFormatter: { type: Function, default: null }
  },

  emits: ["click:row"],

  data() {
    return {
      results: [],
      loading: true,
      totalResults: 0,
      reportParams: {
        page: this.page,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        customer_id: this.customerId,
        query: this.query
      },
      search: this.query
    };
  },

  watch: {
    reportParams(oldValue, newValue) {
      if (isEqual(oldValue, newValue)) {
        return;
      }

      this.fetchData();
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.loading = true;
      const response = await API.makeRequest(this.reportPath, {
        method: "GET",
        params: this.reportParams
      });
      this.totalResults = response.data.recordsFiltered;
      this.results = this.formatRows(response.data.data);
      this.loading = false;
    },
    formatRows(data) {
      if (!this.rowFormatter) {
        return data;
      } else {
        return data.map(this.rowFormatter);
      }
    },
    updateOptions(options) {
      this.reportParams = {
        ...this.reportParams,
        page: options.page,
        per_page: options.itemsPerPage,
        sort_by: options.sortBy[0],
        sort_desc: options.sortDesc[0]
      };
    },
    updateQuery() {
      this.reportParams = {
        ...this.reportParams,
        query: this.search,
        page: 1
      };
    },
    resetQuery() {
      this.reportParams = {
        ...this.reportParams,
        query: "",
        page: 1
      };
    }
  }
};
</script>

<style lang="scss">
.datatable {
  thead {
    background: var(--v-secondary-base);
    tr th,
    tr th i {
      color: #fff !important;
    }
  }
  td {
    white-space: nowrap;
    height: 40px !important;
  }
  .v-data-footer__select {
    .v-input {
      margin-left: 10px !important;
    }
  }
  .v-data-footer__pagination {
    margin: 0 !important;
  }
  .v-data-footer__icons-before,
  .v-data-footer__icons-after {
    .v-btn {
      margin: 0 !important;
    }
  }
}
.search {
  max-width: 320px;
}
</style>
