<template>
  <div v-if="customer" class="paymentMethods bg-white">
    <PageTabs v-model="paymentType" :tabs="paymentMethodTabs" />
    <div class="fields">
      <div v-if="filteredPaymentMethodOptions.length && !loadingPaymentMethods">
        <div v-if="paymentType === 'stax_ach'">
          <StaxAchForm
            ref="staxAchForm"
            :show-save-payment="!storedMethodsMode && isBillingCustomer"
            :form="forms.stax_ach"
          />
        </div>
        <div v-if="paymentType === 'stax_card'">
          <StaxCardForm
            ref="staxCardForm"
            :show-save-payment="!storedMethodsMode && isBillingCustomer"
          />
        </div>
        <div v-if="paymentType === 'gpi_card'">
          <GPICardForm
            ref="gpiForm"
            :show-save-payment="!storedMethodsMode && isBillingCustomer"
          />
        </div>
        <CashForm v-if="paymentType === 'cash'" />
        <CheckForm v-if="paymentType === 'check'" :form="forms.check" />
        <ACHForm
          v-if="paymentType === 'gpi_ach'"
          ref="achForm"
          :show-save-payment="!storedMethodsMode && isBillingCustomer"
          :form="forms.gpi_ach"
        />
        <ExternalPaymentForm
          v-if="paymentType === 'external'"
          :form="forms.external"
        />
        <div v-if="showNotes" class="notes-padding">
          <v-textarea v-model="notes" label="Notes" class="notes-input" />
        </div>
      </div>
      <span
        v-if="
          filteredPaymentMethodOptions.length === 0 && !loadingPaymentMethods
        "
        class="no-results"
        >No Payment Methods Available</span
      >
      <span v-if="loadingPaymentMethods" class="no-results-message">
        Loading...
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Customer } from "serviceshift-ui/shared/src/typings/customer";
import {
  PaymentMethodType,
  storablePaymentMethods
} from "serviceshift-ui/shared/src/typings/paymentMethods";
import { computed, ref, watch } from "vue";

import usePaymentMethods from "../usePaymentMethods";
import ACHForm from "./ACHForm.vue";
import CashForm from "./CashForm.vue";
import CheckForm from "./CheckForm.vue";
import ExternalPaymentForm from "./ExternalPaymentForm.vue";
import GPICardForm from "./GPICardForm.vue";
import PageTabs from "./PageTabs.vue";
import StaxAchForm from "./StaxAchForm.vue";
import StaxCardForm from "./StaxCardForm.vue";

const {
  customer,
  paymentMethodOptions,
  firstName,
  lastName,
  loadingPaymentMethods
} = usePaymentMethods();

const props = defineProps({
  customer: {
    type: Object as () => Customer,
    default: () => null
  },
  storedMethodsMode: {
    type: Boolean,
    default: () => false
  },
  paymentMethods: {
    type: Array as () => PaymentMethodType[],
    default: () => []
  },
  showNotes: {
    type: Boolean,
    default: () => false
  },
  isBillingCustomer: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["tab-switch"]);

const achForm = ref(null as any);
const gpiForm = ref(null as any);
const staxCardForm = ref(null as any);
const staxAchForm = ref(null as any);
const paymentType = ref(null as null | PaymentMethodType);
const selectedPaymentMethodId = ref(null as null | number);
const addingCard = ref(false);
const notes = ref("");
const forms = ref({
  check: {
    check_dl_number: "",
    check_dl_state: "",
    check_number: ""
  },
  external: {
    reference_id: "",
    processor_name: "",
    notify_customer: true
  },
  gpi_ach: {
    ach_first_name: "",
    ach_last_name: "",
    ach_account_type: "checking",
    ach_account_number: "",
    ach_routing_number: "",
    ach_save_payment_method: false
  },
  stax_ach: {
    ach_first_name: "",
    ach_last_name: "",
    ach_account_type: "",
    ach_holder_type: "",
    ach_account_number: "",
    ach_routing_number: "",
    ach_save_payment_method: false
  }
});

const paymentMethodTabs = computed(() => {
  return filteredPaymentMethodOptions.value.filter((option) => {
    return option.key !== "payment_method";
  });
});

const filteredPaymentMethodOptions = computed(() => {
  if (props.storedMethodsMode) {
    return paymentMethodOptions.value.filter((paymentMethod) => {
      return storablePaymentMethods.includes(paymentMethod.key);
    });
  }
  if (!props.paymentMethods.length) return paymentMethodOptions.value;
  return paymentMethodOptions.value.filter((paymentMethod) => {
    return props.paymentMethods.includes(paymentMethod.key);
  });
});

defineExpose({
  getData,
  resetForms
});

watch(paymentType, (val, newVal) => {
  if (val !== newVal) emit("tab-switch");
});

watch(
  customer,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      setDefaultPaymentMethod();
      forms.value.gpi_ach.ach_first_name = firstName.value;
      forms.value.gpi_ach.ach_last_name = lastName.value;
      forms.value.stax_ach.ach_first_name = firstName.value;
      forms.value.stax_ach.ach_last_name = lastName.value;
    }
  },
  { immediate: true }
);

watch(
  filteredPaymentMethodOptions,
  (newValue, oldValue) => {
    if (newValue !== oldValue && newValue.length && !paymentType.value) {
      setDefaultPaymentType();
    }
  },
  { immediate: true }
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getData() {
  return new Promise((resolve, reject) => {
    const formData = forms.value[paymentType.value || ""] || {};
    const params = {
      transaction_type: getTransactionType(),
      notes: props.showNotes ? notes.value : undefined,
      ...formData
    };

    if (paymentType.value === "gpi_ach") {
      return achForm.value
        .getData()
        .then((data) => {
          resolve({ ...params, ...data });
        })
        .catch((e) => {
          reject(e);
        });
    }

    if (paymentType.value === "gpi_card") {
      return gpiForm.value
        .getData()
        .then((cardData) => {
          resolve({ ...params, ...cardData });
        })
        .catch((e) => {
          reject(e);
        });
    }

    if (paymentType.value === "stax_card") {
      return staxCardForm.value
        .getData()
        .then((cardData) => {
          resolve({ ...params, ...cardData });
        })
        .catch((e) => {
          reject(e);
        });
    }

    if (paymentType.value === "stax_ach") {
      return staxAchForm.value
        .getData()
        .then((achData) => {
          resolve({ ...params, ...achData });
        })
        .catch((e) => {
          reject(e);
        });
    }

    resolve(params);
  });
}

function getTransactionType() {
  const paymentMethod = filteredPaymentMethodOptions.value.find(
    (method) => method.key === paymentType.value
  );
  return paymentMethod ? paymentMethod.transaction_type : paymentType.value;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function resetForms() {
  selectedPaymentMethodId.value = null;
  addingCard.value = false;
  notes.value = "";
  forms.value = {
    check: {
      check_dl_number: "",
      check_dl_state: "",
      check_number: ""
    },
    external: {
      reference_id: "",
      processor_name: "",
      notify_customer: true
    },
    gpi_ach: {
      ach_first_name: firstName.value,
      ach_last_name: lastName.value,
      ach_account_type: "checking",
      ach_account_number: "",
      ach_routing_number: "",
      ach_save_payment_method: false
    },
    stax_ach: {
      ach_first_name: firstName.value,
      ach_last_name: lastName.value,
      ach_account_type: "",
      ach_holder_type: "",
      ach_account_number: "",
      ach_routing_number: "",
      ach_save_payment_method: false
    }
  };
  if (gpiForm.value) {
    gpiForm.value.resetForm();
  }
  if (staxCardForm.value) {
    staxCardForm.value.resetForm();
  }
  setDefaultPaymentMethod();
  setDefaultPaymentType();
}

function setDefaultPaymentMethod() {
  if (customer.value?.payment_method) {
    selectedPaymentMethodId.value = customer.value.payment_method.id || null;
    addingCard.value = false;
  } else {
    addingCard.value = true;
  }
}

function setDefaultPaymentType() {
  // If the first payment method is a saved payment method, ignore and use the next
  const firstMethod = filteredPaymentMethodOptions.value[0];
  if (firstMethod.key === "payment_method") {
    paymentType.value = filteredPaymentMethodOptions.value[1].value;
    return;
  }
  paymentType.value = filteredPaymentMethodOptions.value[0].value;
}
</script>

<style lang="scss">
.paymentMethods {
  margin: 0 auto;
  .fields {
    background: #fff;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .notes-input {
    margin: 30px;
    textarea {
      background: white !important;
    }
  }

  // Styles to make sure vuetify inputs look like GPI
  .v-input__slot,
  .gpi-field,
  .stax-field {
    width: calc(
      100% - 1px
    ); // TODO: Why does making it 100% cut off the right border?
    background: #ffffff;
    border: 1px solid #a8a8a8;
    border-radius: 4px;
    box-shadow: none !important;
  }
  .field-label,
  .label {
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
    font-size: 14px !important;
    margin-bottom: 2px;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
  .v-messages,
  .v-text-field__details {
    min-height: 0 !important;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.no-results {
  justify-content: center;
  display: flex;
  color: #7e8fb4;
  text-align: center;
  margin-bottom: 20px;
}
.toggle-label {
  font-size: 1rem;
  white-space: nowrap;
}
.toggle {
  flex-wrap: wrap;
}
</style>
