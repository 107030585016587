<template>
  <div>
    <Page title="All Quotes" max-width="850">
      <p class="text-center">
        Review or request service from {{ tenant?.name }} for the following
        quotes by selecting the Proceed with Service option.
      </p>
      <Datatable
        :headers="headers"
        :report-path="'/reports/consumer/quotes.json'"
        :sort-by="'id'"
        :sort-desc="true"
        :customer-id="customerIds"
      >
        <template #item.actions="{ item }">
          <div
            v-if="
              item.billing_customer_id.toString() ===
              $store.state.user?.id.toString()
            "
            class="d-flex justify-end align-center"
          >
            <div class="d-flex">
              <a href="" @click.prevent="toggleProceedModal(item)">
                Proceed with Service
              </a>
              <v-divider vertical class="mx-3"></v-divider>
            </div>
            <router-link
              :to="{ name: 'quotePreview', params: { id: item.id } }"
            >
              View Quote
            </router-link>
          </div>
        </template>
      </Datatable>
    </Page>
    <quote-proceed-modal
      v-if="proceedModal && selectedQuote"
      :quote="selectedQuote"
      @hide-modal="toggleProceedModal"
      @save-success="toggleProceedSuccess"
    ></quote-proceed-modal>
    <success-toast v-if="proceedSuccess" @hide-modal="toggleProceedSuccess">
      <div slot="body">
        <p>Your request for service has been successfully submitted.</p>
      </div>
    </success-toast>
  </div>
</template>

<script setup lang="ts">
import { Tenant } from "serviceshift-ui/shared/src/typings/tenant";
import { computed, ref } from "vue";
import { useStore } from "@/lib/vuex-composition";

import Datatable from "@/components/Datatable.vue";
import Page from "@/components/Page.vue";
import QuoteProceedModal from "@/components/QuoteProceedModal.vue";
import SuccessToast from "@/components/SuccessToast.vue";

const store = useStore();

const proceedModal = ref(false);
const selectedQuote = ref(null);
const proceedSuccess = ref(false);

const tenant = computed<Tenant | null>(() => store.state.tenant);
const headers = computed(() => {
  if (!store.state.user) return [];
  const headers = [
    { text: "Date", value: "date" },
    { text: "Name", value: "name" },
    { text: "Description", value: "summary" },
    { text: "Quote #", value: "id" },
    { text: "Job #", value: "job_id" },
    { text: "Total", value: "total", align: "end" },
    { text: "", value: "actions", sortable: false }
  ];
  if (store.state.user.children?.length) {
    headers.unshift({ text: "Account", value: "customer_name" });
  }
  return headers;
});
const customerIds = computed<string | undefined>(() => {
  if (!store.state.user) return undefined;
  if (!store.state.user.children?.length) {
    return store.state.user.id.toString();
  }
  return [store.state.user.id]
    .concat(store.state.user?.children.map((child) => child.id))
    .join(",");
});
const toggleProceedModal = (quote) => {
  selectedQuote.value = quote;
  proceedModal.value = !proceedModal.value;
};
const toggleProceedSuccess = () => {
  proceedSuccess.value = !proceedSuccess.value;
};
</script>

<style lang="scss"></style>
