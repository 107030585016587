<template>
  <div v-if="paymentMethodsAvailable" class="full-width">
    <InvoiceBalance
      v-for="invoice in payableInvoices"
      :key="invoice.id"
      class="full-width mb-4"
      :invoice="invoice"
      @pay-balance="emit('pay-balance', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
import API from "serviceshift-ui/api-client";
import { computed, onMounted, ref } from "vue";
import { useStore } from "@/lib/vuex-composition";

import InvoiceBalance from "@/modules/paymentMethods/components/InvoiceBalance.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";

const store = useStore();
const reportPath = "/reports/consumer/invoices.json";
const reportParams = {
  page: 1,
  per_page: 10,
  sort_by: "id",
  sort_desc: true,
  balance_due: true
};

const invoices = ref([]);

const emit = defineEmits(["pay-balance"]);

async function fetchData() {
  const response = await API.makeRequest(reportPath, {
    method: "GET",
    params: reportParams
  });
  invoices.value = response.data.data;
}

onMounted(() => fetchData());

const payableInvoices = computed(() => {
  return invoices.value.filter(
    (invoice) =>
      invoice.billing_customer_id?.toString() ===
      store.state.user?.id.toString()
  );
});

const { definePaymentCallbacks, paymentMethodsAvailable } = usePaymentMethods();
definePaymentCallbacks({
  onPaymentSuccess: fetchData
});
</script>

<style lang="scss"></style>
