<template>
  <div>
    <Page title="Your Appointments" max-width="850">
      <UnpaidInvoices @pay-balance="handlePayBalance" />
      <Datatable
        ref="datatable"
        :headers="headers"
        :report-path="'/reports/consumer/appointments.json'"
        :sort-by="'date'"
        :sort-desc="true"
        :instruction-text="'Select any row to view details'"
        :customer-id="customerIds"
        @click:row="handleRowClick"
      />
    </Page>
    <InvoicePaymentModal
      v-model="showPaymentModal"
      :invoice-id="selectedInvoiceId"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router/composables";
import { useStore } from "@/lib/vuex-composition";

import Datatable from "@/components/Datatable.vue";
import InvoicePaymentModal from "@/components/InvoicePaymentModal.vue";
import Page from "@/components/Page.vue";
import UnpaidInvoices from "@/components/UnpaidInvoices.vue";

const router = useRouter();
const store = useStore();

const headers = computed(() => {
  if (!store.state.user) return [];
  const headers = [
    { text: "Date", value: "date" },
    { text: "Location", value: "address_name" },
    { text: "Service Address", value: "address_short" },
    { text: "Appointment", value: "service_category_name" },
    { text: "Total", value: "invoice_total" },
    { text: "Status", value: "status" }
  ];
  if (store.state.user.children?.length) {
    headers.unshift({ text: "Account", value: "customer_name" });
  }
  return headers;
});
const selectedInvoiceId = ref<number | undefined>(undefined);
const showPaymentModal = ref(false);

const customerIds = computed<string | undefined>(() => {
  if (!store.state.user) return undefined;
  if (!store.state.user.children?.length) {
    return store.state.user.id.toString();
  }
  return [store.state.user.id]
    .concat(store.state.user?.children.map((child) => child.id))
    .join(",");
});

const handleRowClick = (item) => {
  router.push({
    name: "appointmentSummary",
    params: { id: item.id }
  });
};
const handlePayBalance = (invoiceId) => {
  selectedInvoiceId.value = invoiceId;
  showPaymentModal.value = true;
};
</script>

<style lang="scss"></style>
